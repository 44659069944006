import request from '@/utils/request'

// 分页查询业务征集
export function businessList(data) {
    return request({
        url: '/management/businessList',
        method: 'get',
        params: data
    })
}

// 分页查询我的发布
export function myReleaseList(data) {
    return request({
        url: '/management/myReleaseList',
        method: 'get',
        params: data
    })
}

// 更新发布状态
export function updateReleaseState(data) {
    return request({
        url: '/management/updateReleaseState',
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
// 分页查询技术申请
export function technologyList(data) {
    return request({
        url: '/management/technologyList',
        method: 'get',
        params: data
    })
}

// 分页查询资金申请
export function moneyList(data) {
    return request({
        url: '/management/moneyList',
        method: 'get',
        params: data
    })
}

// 分页查询服务申请
export function viceList(data) {
    return request({
        url: '/management/viceList',
        method: 'get',
        params: data
    })
}




// 分页查询活动报名
export function activityList(data) {
    return request({
        url: '/management/activityList',
        method: 'get',
        params: data
    })
}

//查询活动
export function selPolitics(data) {
    return request({
        url: '/com/activity/sel',
        method: 'get',
        params: data
    })
}
