<!-- 服务申请 -->
<template>
  <div class="com-box">
    <div v-show="!detailShow">
    <div>
      <div class="add-title">服务申请</div>
      <div style="margin-top: 17px;border-bottom:1px solid #DCDFE6; overflow:hidden; "></div>
    </div>
    <div class="tables">
      <a-table
          :data-source="data"
          :columns="columns"
          :pagination="false"
      >
      <span slot="action" slot-scope="record">
        <a-button type="link" @click="show(record)">查看</a-button>
      </span>
      </a-table>
      <a-pagination show-quick-jumper
                    :show-total="total => `共 ${total} 条`"
                    :default-current="this.pageNum"
                    :total="this.total"
                    :defaultPageSize="this.pageSize"
                    :hideOnSinglePage="true"
                    @change="onChange"/>
    </div>
    </div>
    <div class="gov-detailS" v-show="detailShow">
      <a-page-header
          title="返回"
          @back="detailShow=false"
      />
      <vicedetails  ref="vicedetails"></vicedetails>
    </div>
  </div>
</template>

<script>
import { viceList} from "../../../api/company/personal";
import vicedetails from "../../personal/details/vicedetails";

const columns = [
  {
    title: '名称',
    dataIndex: 'name',
    width: 70,
    key: 'name',
  },
  {
    title: '时间',
    dataIndex: 'applyTime',
    align:'center',
    width: 100,
    key: 'applyTime',
  },
  {
    title: '操作',
    align:'center',
    width: 80,
    scopedSlots: { customRender: 'action' },
  }
];
const data = [];

export default {
  name:'serveApply',
  components:{
    vicedetails
  },
  data() {
    return {
      detailShow: false,
      pageNum: 1,
      pageSize: 5,
      total: 10,
      data,
      columns
    }
  },
  methods: {
    init() {
      viceList({'pageNum': this.pageNum, 'pageSize': this.pageSize}).then(res => {
        let {total, records} = res.data
        this.data = records
        this.total = total
      })
    },
    onChange(obj) {
      this.pageNum = obj
      this.init()
    },
    show(obj){
      this.$refs.vicedetails.showDetail(obj.id)
      this.detailShow = true
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.com-box {
  min-height: calc(100vh - 142px);
  padding: 20px;
  color: #000000;
}

.add-title {
  text-align: left;
  font-size: 20px;
  font-weight: bold
}

.gov-detailS {
  width: 100%;
  background: #f8fbff;
  padding: 0px 30px;
  color: #666666;
  position: relative;

  .gov-detail-top {
    //text-align: center;
    line-height: 36px;
    border-bottom: 2px solid #efefef;
    padding-bottom: 10px;

    .gov-detail-top-title {
      color: #333333;
      font-weight: 600;
      font-size: 22px;
    }

    .gov-detail-top-date {
      font-size: 16px;
    }
  }

  .gov-detail-content {
    margin-top: 40px;
    line-height: 36px;
  }
}
</style>
