<!-- 业务收集 -->
<template>
  <div class="com-box">
    <div v-show="!detailShow">
      <div>
        <div class="add-title" style="border-bottom: 2px solid #efefef;margin-bottom: 10px">业务征集</div>
        <div class="">
          <a-select  style="width: 300px" @change="onSearch" placeholder="请选择业务类型搜索">
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option value="0">
              需求
            </a-select-option>
            <a-select-option value="1">
              技术
            </a-select-option>
            <a-select-option value="2">
              活动
            </a-select-option>
            <a-select-option value="3">
              服务
            </a-select-option>
          </a-select>
        </div>
        <div style="margin-top: 17px;border-bottom:1px solid #DCDFE6; overflow:hidden; "></div>
      </div>
      <div class="tables">
        <a-table
            :data-source="data"
            :columns="columns"
            :pagination="false"
        >
      <span slot="action" slot-scope="record">
        <a-button type="link" @click="show(record)">查看</a-button>
      </span>
        </a-table>
        <a-pagination
            show-quick-jumper
            :show-total="total => `共 ${total} 条`"
            :default-current="this.pageNum"
            :total="this.total"
            :pageSize="pageSize"
            @change="onChange"
            :hideOnSinglePage="true"/>
      </div>
    </div>
    <div  v-show="detailShow">
      <a-page-header
          title="返回"
          @back="detailShow=false"
      />
      <demanddetails v-show="this.type === 0" ref="demanddetails"></demanddetails>
      <technologydetails v-show="this.type === 1" ref="technologydetails"></technologydetails>
      <sciencedetails v-show="this.type === 2" ref="sciencedetails"></sciencedetails>
      <vicedetails v-show="this.type === 3" ref="vicedetails"></vicedetails>
    </div>
  </div>
</template>

<script>
import {businessList} from "../../../api/company/personal";
import demanddetails from "../../personal/details/demanddetails";
import technologydetails from "../../personal/details/technologydetails";
import sciencedetails from "../../personal/details/sciencedetails";
import vicedetails from "../../personal/details/vicedetails";

const columns = [
  {
    title: '业务名称',
    dataIndex: 'projectName',
    key: 'projectName',
  },
  {
    title: '业务类型',
    dataIndex: 'typeLabel',
    key: 'typeLabel',
  },
  {
    title: '提交人',
    dataIndex: 'contactName',
    key: 'contactName',
  },
  {
    title: '邮箱',
    dataIndex: 'contactEmail',
    key: 'contactEmail',
  },
  {
    title: '手机号',
    dataIndex: 'contactPhone',
    key: 'contactPhone',
  },
  {
    title: '提交时间',
    dataIndex: 'applyTime',
    key: 'applyTime',
  },
  {
    title: '操作',
    align: 'center',
    scopedSlots: {customRender: 'action'},
  }
];
const data = [];

export default {
  name: 'business',
  components: {
    demanddetails ,technologydetails,sciencedetails,vicedetails
  },
  data() {
    return {
      key:null,
      detailShow: false,
      type : undefined,
      pageNum: 1,
      pageSize: 5,
      total: 10,
      data,
      columns
    }
  },
  methods: {
    onSearch(val) {
      this.key=val;
      this.init()
    },
    init() {
      businessList({'pageNum': this.pageNum, 'pageSize': this.pageSize,key:this.key}).then(res => {
        let {total, records} = res.data
        this.data = records
        this.total = total
        //console.log(this.total)
      })
    },
    onChange(obj) {
      this.pageNum = obj
      this.init()
    },
    show(obj) {
      this.init()
      if (obj.type === 0) {
        // 说明是业务类型是需求
        this.$refs.demanddetails.showDetail(obj.projectId)
        this.type = obj.type
        this.detailShow = true
      }else if (obj.type === 1) {
        //说明是业务类型是技术
        this.$refs.technologydetails.showDetail(obj.projectId)
        this.type = obj.type
        this.detailShow = true
      }else if (obj.type === 2) {
        //说明是业务类型是活动
        this.$refs.sciencedetails.showDetail(obj.projectId)
        this.type = obj.type
        this.detailShow = true
      }else if (obj.type === 3) {
        // 说明是业务类型是服务
        this.$refs.vicedetails.showDetail(obj.projectId)
        this.type = obj.type
        this.detailShow = true
      }
    }
  },
  mounted() {
    this.init();
  }
}
</script>

<style lang="scss" scoped>

.com-box {
  min-height: calc(100vh - 142px);
  padding: 20px;
  color: #000000;
}

.add-title {
  text-align: left;
  font-size: 20px;
  font-weight: bold
}

.gov-detailS {
  width: 1080px;
  background: #f8fbff;
  padding: 0px 30px;
  color: #666666;
  position: relative;
  .gov-detail-top {
    //text-align: center;
    line-height: 36px;
    border-bottom: 2px solid #efefef;
    padding-bottom: 10px;
    .gov-detail-top-title {
      color: #333333;
      font-weight: 600;
      font-size: 22px;
    }
    .gov-detail-top-date {
      font-size: 16px;
    }
  }
  .gov-detail-content {
    margin-top: 40px;
    line-height: 36px;
  }
}
</style>
