<template>
  <div class="account">
    <div style="float:right;">
      <a-button style="z-index: 1;" @click="openEditPassHandle">
        修改密码
      </a-button>
    </div>
    <div v-if="type === '1'" style="float:left;display: flex">
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol"
                      :wrapper-col="wrapperCol">
          <a-form-model-item label="机构分类" prop="dictId">
            <span class="info">{{ form.typeLabel }}</span>
          </a-form-model-item>
          <a-form-model-item label="单位机构名称" prop="name">
            <span class="info">{{ form.name }}</span>
          </a-form-model-item>
          <a-form-model-item :label="form.codeType === 1 ? '组织机构代码':'统一社会信用码'" prop="code">
            <span class="info">{{ form.code }}</span>
          </a-form-model-item>
          <a-form-model-item label="机构logo" prop="logo">
            <a-upload
                name="avatar"
                list-type="picture-card"
                :customRequest="customImgRequest"
                :file-list="imgList"
                @preview="handlePreview"
                :before-upload="beforeUpload"
                @change="imgChange"
            >
              <div v-if="form.logo ===null">
                <a-icon type="plus"/>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage"/>
            </a-modal>
          </a-form-model-item>
          <a-form-model-item label="邮箱" prop="email">
            <a-input v-model="form.email" style="width: 435px;"/>
          </a-form-model-item>
          <a-form-model-item label="机构地址" prop="provinceAndCityArray">
            <a-cascader :options="options" v-model="form.provinceAndCityArray" style="width: 435px"/>
          </a-form-model-item>
          <a-form-model-item label="详细地址" prop="detailedAddress">
            <a-input v-model="form.detailedAddress" style="width: 435px"/>
          </a-form-model-item>
          <a-form-model-item label="机构经纬度" required>
            <div style="display: flex;">
              <div style="margin-right: 10px">
                <a-form-model-item prop="longitudeLatitude">
                  <a-input v-model="form.longitudeLatitude" style="width: 320px;"/>
                </a-form-model-item>
              </div>
              <div>
                <a-button @click="openJwdHandle" style="background: #E1E1E1;color: #666666;">获取经纬度</a-button>
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item label="行业领域" prop="industryField">
            <a-select v-model="form.industryField" placeholder="请选择行业领域"
                      style="width:435px;">
              <a-select-option v-for="(item) in technicalList"
                               :key="item.id">{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="联系人" prop="contact">
            <a-input v-model="form.contact" style="width: 435px"/>
          </a-form-model-item>
          <a-form-model-item label="机构网址" prop="url">
           <a-input v-model="form.url" style="width: 435px"/>
          </a-form-model-item>
          <a-form-model-item label="认证文件" prop="certificationFile">
            <a-row>
              <a-col :span="23"><a-upload
                :multiple="true"
                :file-list="fileList"
                :customRequest="customRequest"
                @change="handleChange"
            >
              <a-button>
                <a-icon type="upload"/>
                上传
              </a-button>
              </a-upload></a-col>
            <a-col :span="1" style="height: 10px;margin-top: 60px;position: relative;top:20px"><div class="tip_div">
              <div class="content_cla" >
                <p>为验证您单位信息真实，请将以下证件扫描件上传至站点：</p>
                <p>1.组织机构代码/统一社会信用代码</p>
                <p>2.企业法人营业执照</p>
              </div>
            </div>
            </a-col>
            </a-row>
          </a-form-model-item>
          <a-form-model-item label="手机号" prop="mobilePhone">
            <a-input v-model="form.mobilePhone"
                              :max-length="Number(11)"
                              @keydown="handleInput"
                              style="width: 435px"/>
          </a-form-model-item>
          <a-form-model-item label="机构简介" prop="intro">
            <Tinymce ref="Tinymce" v-model="form.intro" width="600px"></Tinymce>
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button
                style="margin-left: 28px;width: 119px;height: 42px;background: #075FEA;color: #FFFFFF;font-size: 18px;"
                @click="onSubmit">
              保存
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div>
        <div class="tip_div1">
          <div class="content_cla" style="line-height: 19px;height: 67px;">
            <p>最佳显示效果：图片高度为150像素，宽度为200像素，透明背景（建议先处理好图片再上传）</p>
          </div>
        </div>

      </div>
    </div>
    <div v-else>
      <a-form-model ref="singleForm" :model="singleForm" :rules="rules2" :label-col="labelCol"
                    :wrapper-col="wrapperCol">
        <a-form-model-item label="邮箱" prop="email">
          <a-input v-model="singleForm.email" style="width: 435px;"/>
        </a-form-model-item>
        <a-form-model-item label="中文姓名" prop="chineseName">
          <a-input v-model="singleForm.chineseName" style="width: 435px;"/>
        </a-form-model-item>
        <a-form-model-item label="所在地址" prop="provinceAndCityArray">
          <a-cascader :options="options" v-model="singleForm.provinceAndCityArray" style="width: 435px;"/>
        </a-form-model-item>
        <a-form-model-item label="学科分类" prop="subjectClassification">
          <a-select v-model="singleForm.subjectClassification" placeholder="请选择学科分类"
                    style="width:435px;">
            <a-select-option v-for="(item) in subjectClassificationList"
                             :key="item.id">{{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="当前工作机构" prop="organization">
          <a-input v-model="singleForm.organization" style="width: 435px"/>
        </a-form-model-item>
        <a-form-model-item label="部门" prop="dept">
          <a-input v-model="singleForm.dept" style="width: 435px"/>
        </a-form-model-item>
        <a-form-model-item label="职称" prop="title">
          <a-input v-model="singleForm.title" style="width: 435px"/>
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="mobilePhone">
          <a-input v-model="singleForm.mobilePhone"
                   :max-length="Number(11)"
                   @keydown="handleInput"
                   style="width: 435px"/>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button
              style="margin-left: 28px;width: 119px;height: 42px;background: #075FEA;color: #FFFFFF;font-size: 18px;"
              @click="onSubmit2">
            保存
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <queryBaidu
        :visible.sync="visible1"
        v-if="visible1"
        @onSuccess="getLatitude"
    ></queryBaidu>
    <EditPass ref="EditPass"></EditPass>
  </div>
</template>

<script>
import Tinymce from '../../components/Tinymce'
// import validCode from '@/components/identify'
// import distpicker from 'distpicker'
import {regionData} from 'element-china-area-data'
// import {AreaCascader} from 'vue-area-cascader'
import {getSubject, userDetails, institutionsDetails, userUpdate, institutionsUpdate} from "../../api/user";
import {ossUpload} from "../../api/uploader";
import {getType} from '../../utils/auth'
import {institutionsTypeList, technicalList} from '@/api/company/institutions'
import EditPass from '../components/editPass'
import queryBaidu from "./BaiDuMap";

const form = {
  logo: null,
  intro: null,
  longitudeLatitude: null,
  typeLabel: null,
  name: null,
  codeType: null,
  dictId: null,
  code: null,
  email: null,
  detailedAddress: null,
  industryField: null,
  contact: null,
  url: null,
  certificationFile: null,
  mobilePhone: null,
  provinceAndCityArray: [],
  zCode: null,
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
  name: "AccountSet",
  components: {EditPass, Tinymce, queryBaidu},
  data() {
    return {
      visible1: false,
      imgList: [
        // {
        //   uid: '-1',
        //   name: 'image.png',
        //   status: 'done',
        //   url: '',
        // },
      ],
      previewImage: null,
      previewVisible: false,
      loading: false,
      options: regionData,
      fList: [],
      fileList: [],
      codeTypeList: [
        '组织机构代码',
        '统一社会信用代码'
      ],
      type: null,
      subjectClassificationList: null,
      technicalList: [],
      classificationList: [],
      institutionsTypeList: [],
      labelCol: {span: 5},
      wrapperCol: {span: 15},
      form: Object.assign({}, form),
      singleForm: null,
      rules: {
        dictId: [{required: true, message: '请选择机构分类', trigger: 'blur'}],
        name: [{required: true, message: '请输入单位机构名称', trigger: 'blur'}],
        logo: [{required: true, message: '请上传企业logo', trigger: 'blur'}],
        code: [{required: true, message: '请输入代码', trigger: 'blur'}],
        email: [{required: true, message: '请输入邮箱', trigger: 'blur'}],
        provinceAndCityArray: [{required: true, message: '请选择省市区', trigger: 'blur'}],
        detailedAddress: [{required: true, message: '请输入详细地址', trigger: 'blur'}],
        longitudeLatitude: [{required: true, message: '请输入机构的经纬度', trigger: 'blur'}],
        industryField: [{required: true, message: '请选择行业领域', trigger: 'blur'}],
        contact: [{required: true, message: '请输入联系人', trigger: 'blur'}],
        url: [{required: true, message: '请输入机构网址', trigger: 'blur'}],
        certificationFile: [{required: true, message: '请上传认证文件', trigger: 'blur'}],
        mobilePhone: [{required: true, message: '请输入手机号码', trigger: 'blur'}],
      },
      rules2: {
        email: [{required: true, message: '请输入邮箱', trigger: 'blur'}],
        chineseName: [{required: true, message: '请输入中文名', trigger: 'blur'}],
        provinceAndCityArray: [{required: true, message: '请选择省市区', trigger: 'blur'}],
        subjectClassification: [{required: true, message: '请选择学科分类', trigger: 'blur'}],
        organization: [{required: true, message: '请输入当前工作机构', trigger: 'blur'}],
        dept: [{required: true, message: '请选择部门', trigger: 'blur'}],
        title: [{required: true, message: '请输入职称', trigger: 'blur'}],
        mobilePhone: [{required: true, message: '请输入手机号码', trigger: 'blur'}],
      }
    }
  },
  mounted() {
    this.type = getType()
    if (this.type === '2') {
      userDetails().then(res => {
        this.singleForm = res.data
      })
    } else {
      institutionsDetails().then(res => {
        this.form = res.data
        if (this.form.certificationFile === "") {
          this.form.certificationFile = null
        }
        if (this.form.logo !== "" && this.form.logo !== null) {
          this.imgList.push({uid: '-1',
                            name: 'image.png',
                            status: 'done',
                             url: '',
                           });
          this.imgList[0].url = this.form.logo
        }
        if (this.form.certificationFile !== null) {
          let files = this.form.certificationFile.split(',')
          //console.log(files,'===================================')
          this.fList = files
          for (let i = 0; i < files.length; i++) {
            let file = files[i]
            let splitArray = file.split("/")
            let fName = splitArray[splitArray.length - 1]
            this.fileList.push({uid: fName.split('.')[0], name: fName, status: 'done', url: file})
          }
        }
      })
    }
    this.getSubjectHandle()
    this.technicalListHandle()
    this.institutionsTypeListHandler()
  },
  methods: {
    openJwdHandle() {
      //console.log(this.visible1 = true)
      this.visible1 = true
    },
    getLatitude(f) {
      this.form.longitudeLatitude = f[0].lat + ',' + f[0].lng
    },
    imgChange(info) {
      let file = info.file
      if (file.status === "removed") {
        this.form.logo = null
        this.imgList = []
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('只允许上传jpeg/png图片');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('文件大小需小于2MB!');
      }
      return isJpgOrPng && isLt2M;
    },
    onSubmit() {
      let urls = []
      for (let i = 0; i < this.fileList.length; i++) {
        let file = this.fileList[i]
        urls.push(file.url)
      }
      this.form.certificationFile = urls.join(",")
      this.$refs.form.validate(valid => {
        if (valid) {
          institutionsUpdate(this.form).then(res => {
            this.$message.success(res.msg)
          })
        }
      })
    },
    onSubmit2() {
      this.$refs.singleForm.validate(valid => {
        if (valid) {
          userUpdate(this.singleForm).then(res => {
            this.$message.success(res.msg)
          })
        }
      })
    },
    openEditPassHandle() {
      this.$refs.EditPass.show()
    },
    getSubjectHandle() {
      getSubject().then(res => {
        this.subjectClassificationList = res.data
      })
    },
    handleInput(e) {
      let a = e.key.replace(/[^\d]/g, "");
      if (!a && e.keyCode !== 8) {
        e.preventDefault();
      }
    },
    handleChange(info) {
      let file = info.file
      if (file.status === "removed") {
        this.fileList = [...info.fileList];
      }
    },
    customImgRequest(data) {
      const formData = new FormData();
      formData.append("pic", data.file)
      ossUpload(formData).then(res => {
        this.form.logo = res.data.url
        this.imgList.push({
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: this.form.logo,
        },)
      })
    },
    customRequest(data) {
      const formData = new FormData();
      formData.append("pic", data.file)
      ossUpload(formData).then(res => {
        let url = res.data.url
        let splitUrl = url.split("/")
        let fName = splitUrl[splitUrl.length - 1]
        this.fileList.push({uid: fName.split('.')[0], name: fName, status: 'done', url: url})
      })
    },
    setFileList(obj) {
      this.form[obj.field] = obj.img_str
    },
    technicalListHandle() {
      technicalList().then(res => {
        this.technicalList = res.data
      })
    },
    institutionsTypeListHandler() {
      institutionsTypeList().then(res => {
        this.classificationList = res.data
      })
    },
  }
}
</script>

<style lang="scss">
.account {

  .tip_div1 {
    padding: 10px;
    margin-top: 152px;
    width: 487px;
    height: 98px;
    background: #FFF8ED;
    border: 1px solid #FFB94B;
  }

  .tip_div {
    padding: 10px;
    margin-left: 102px;
    width: 487px;
    height: 98px;
    background: #FFF8ED;
    border: 1px solid #FFB94B;
    position: relative;
    bottom: 98px;
  }

  .content_cla {
    font-size: 16px;
    font-weight: normal;
    color: #FF9A02;
    line-height: 12px;


  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  .info {
    color: #333333;
    font-size: 14px;
    font-weight: bold;
  }

  .ant-form-item-label > label {
    color: #666666;
  }

  .ant-input {
    font-weight: bold;
    color: #333333;
  }

  .ant-cascader-picker-label {
    font-weight: bold;
    color: #333333;
  }

  .ant-select-selection-selected-value {
    font-weight: bold;
    color: #333333;
  }
}

/*.account {*/
/*  .info {*/
/*    color: #333333;*/
/*    font-size: 16px;*/
/*    font-weight: normal;*/
/*  }*/

/*  .ant-form-item-label > label {*/
/*    color: #666666;*/
/*  }*/
/*}*/

</style>
