/*找资金*/
<template >
    <div class="gov-detailL" >
      <!--      <a-page-header
                title="返回"
                @back="detailShow=false"
            />-->
      <div class="gov-detail-top">
        <!--        <div class="gov-detail-top-title">
                  {{ selDetail.name }}
                </div>-->
        <div class="gov-detail-top-date">
          <a-row>
            <a-col :span="7"><div class="img3"  style="float: left;height: 300px">
            <img class="img3" style="float: left" :src="selDetail.coverPhoto" >
            <a-button v-if="$store.state.user.isPermissions()==='1'"  style="margin-bottom: 20px;margin-left: 100px" class="button2"  @click="showForm(selDetail)"  type="danger" round>提交需求</a-button>
            </div></a-col>
            <a-col :span="17"><ul style="margin-bottom: 70px;">
            <li class="content content-heda">{{ selDetail.name }}</li>
            <!--            <a-popover >
                          <template slot="content" >
                            <p>{{selDetail.contact}}</p>
                          </template>-->
            <li class="content"><span class="center-span">发布单位：</span>{{selDetail.releaseUnit}}</li>
            <li class="content" ><span class="center-span">产品类型：</span>
              <span v-for="itemA in navData.navList" :key="itemA.id" ><span v-if="selDetail.resultsTypeId===itemA.id">{{ itemA.name}}服务</span></span>
            </li>
            <li class="content" ><span class="center-span">产品简介：</span> {{selDetail.resultsShowsThat}}</li>
            <li class="content" ><span class="center-span">技术领域：</span>{{technicalFieldName}}</li><br/>
            </ul></a-col>
          </a-row>
        </div>
      </div>
      <!--      <span style="border-bottom: 2px solid blue;position:relative;bottom:22px">产品介绍</span>-->


      <div class="gov-detail-content">
        <div class="content-heda">产品介绍：</div>
        <div v-html="selDetail.productIntroduction"></div>
        <div class="content-heda">申请条件：</div>
        <div>{{selDetail.conditions}}</div>
        <div class="content-heda">申请流程：</div>
        <div>{{selDetail.process}}</div>
        <div class="content-heda">联系信息：</div>
        <div>联系人：{{selDetail.contact}}&nbsp;，电话：{{selDetail.contactPhone}}</div>
      </div>
      <moneyApply  v-if="visible" tebel="技术名称" :moneyApplyName="moneyApplyName" :moneyApplyId="moneyApplyId" :visible="visible" />
    </div>
</template>
<script>
import {selPolitics} from "../../../api/company/look_money";
import {getDictType} from "../../../api/company/dictType";
import moneyApply from "../../components/moneyApply.vue";
export default {
  name:'look_moneydetails',
  components:{
    moneyApply
  },
  props:{
    id : Number,
  },
  data() {
    return {
      visible:false,
      moneyApplyName:null,
      moneyApplyId:null,
      technicalFieldList:[],
      technicalFieldName:'',
      navData: {
        navList: [
          { name: "法律法规规章", id: 0 },
          { name: "国家政策", id: 1 },
          { name: "省级政策", id: 2 },
          { name: "市级政策", id: 3 },
          { name: "区级政策", id: 4 },
          { name: "平台政策", id: 5 },
        ],
        title: "找资金",
        default: 0,
      },
      activeName: "法律法规规章",
      detailShow: false,
      selDetail: {
        title:
            "湖南省实施《中华人民共和国促进科技成果转化法》办法（2019年修订）",
        publishTime: "2011/11/11",
        content:
            "产养殖数据库，立足于无人船平台，瞄准服务水产养殖全产业链，用大数据的思维，指导水产企业养殖品类与规模、指导水产养殖原料供应商生产计划、指导水产产品下游企业生产计划、支撑国家水产养殖行业精准扶持、支撑水产养殖市场政策调控，实现了公司从产品经济至数据经济",
      },
    };
  },
  methods: {
      showForm(item){
        //console.log(this.visible)
        this.moneyApplyName=item.name;
        this.moneyApplyId=item.id;
        this.visible=true;
      },
    showDetail(id) {
      //console.log(id);
      this.detailShow = true;
      selPolitics({id:id}).then(res=>{
        //console.log(res);
        this.selDetail=res.data;
        let aaa='';
        for (let i = 0; i <this.technicalFieldList.length ; i++) {
          if(this.selDetail.dictId.indexOf(this.technicalFieldList[i].id)>=0){
            if(aaa===''){
              aaa+=this.technicalFieldList[i].name;
            }else {
              aaa+="/"+this.technicalFieldList[i].name;
            }
          }
        }
        this.technicalFieldName=aaa;
        //console.log(this.selDetail,"this.selDetail");
      })
    },
    init(){
      getDictType(6).then(res=>{
        this.navData.navList=res.data
      })
    }
  },
  mounted() {
    this.init();
    getDictType(30).then(res=>{
      this.technicalFieldList=res.data;
    })
  }
};
</script>
<style lang="scss" scoped>
.gov-detailL {
  height: 100%;
  background: #f8fbff;
  padding: 40px 30px;
  color: #666666;
  position: relative;
  .gov-detail-top {
    // text-align: center;
    line-height: 36px;
    border-bottom: 2px solid #efefef;
    padding-bottom: 20px;
    .gov-detail-top-title {
      color: #333333;
      font-weight: 600;
      font-size: 22px;
    }
    .gov-detail-top-date {
      font-size: 16px;
    }
  }
  .gov-detail-content {
    margin-top: 10px;
    line-height: 36px;
  }
}
</style>