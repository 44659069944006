<template>
  <a-modal
      title="查询经纬度"
      :visible.sync="visible"
      width="650px"
      v-if="visible"
      @cancel="closeDialog"
  >
    <div style="overflow: hidden;">
      <div class="mb10" v-if="addressBox[1]">
        <span style="margin-right:40px">详细地址：{{ addressBox[1] }}</span>
        <span>经纬度：{{ addressBox[0].lng + " " + addressBox[0].lat }}</span>
      </div>
      <baidu-map
          :style="mapStyle"
          ak="4a7b270746a0f3023fcfdfb2b9721908"
          :map-click="false"
          :center="center"
          :zoom="zoom"
          :scroll-wheel-zoom="true"
          @click="getClickInfo"
          @moving="syncCenterAndZoom"
          @moveend="syncCenterAndZoom"
          @ready="onBaiduMapReady"
          @zoomend="syncCenterAndZoom"
      >
        <bm-view style="width: 100%; height: 100%;"/>
        <bm-marker
            :position="{ lng: center.lng, lat: center.lat }"
            :dragging="true"
            animation="BMAP_ANIMATION_BOUNCE"
        />
        <bm-control :offset="{ width: '10px', height: '10px' }">
          <bm-auto-complete
              v-model="keyword"
              :sug-style="{ zIndex: 999999 }"
          >
            <a-input
                v-model="keyword"
                type="text"
                placeholder="请输入地名关键字"
                clearable>
              <i slot="prefix" class="el-input__icon el-icon-search"/>
            </a-input>
          </bm-auto-complete>
        </bm-control>
        <bm-geolocation
            anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
            show-address-bar
            auto-location
        />
        <bm-local-search
            :keyword="keyword"
            :auto-viewport="true"
            :panel="false"
        />
      </baidu-map>
    </div>
    <span slot="footer" class="dialog-footer">
            <a-button icon="el-icon-close" @click="cancel">取 消</a-button>
            <a-button icon="el-icon-place" type="primary" @click="confirm">确 定</a-button>
        </span>
  </a-modal>
</template>

<script>
import {
  BaiduMap,
  BmControl,
  BmView,
  BmAutoComplete,
  BmLocalSearch,
  BmMarker,
  BmGeolocation
} from "vue-baidu-map";

export default {
  components: {
    BaiduMap,
    BmControl,
    BmView,
    BmAutoComplete,
    BmLocalSearch,
    BmMarker,
    BmGeolocation
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // 在子组件中使用 props 选项去接收来自父组件传递过来的数据
    mapHeight: {
      type: Number,
      default: 450
    },
    title: {
      type: String,
      default: "选择目标位置"
    },
    width: {
      type: [Number, String],
      default: "85%"
    },
    height: {
      type: [Number, String],
      default: "80%"
    }
  },
  data() {
    return {
      addressBox: [{}, ""],
      BMap: null, // 地图组件是否就绪
      showMapComponent: this.dialogVisible,
      keyword: "", // 地区搜索的信息
      mapStyle: {
        width: "100%",
        height: this.mapHeight + "px"
      },
      center: {lng: 120.054791, lat: 29.296604},
      choosedLocation: {province: "", city: "", district: "", addr: ""},
      zoom: 15,
      hosName: ""
    };
  },
  watch: {
    visible: function (currentValue) {
      this.showMapComponent = currentValue;
      if (currentValue) {
        this.keyword = "";
      }
    }
  },
  methods: {
    // ready事件：在你需要二次开发或手动控制其子组件，可以使用在此事件中使用返回的 BMap 类和 map 实例进行手动控制
    onBaiduMapReady(e) {
      // //console.log('返回BMap类和map实例', e)
      const that = this;
      this.BMap = e.BMap;
      if (this.BMap) {
        // 获取定位地址信息并赋值给声明变量
        // Geolocation 对象的 getCurrentPosition()、watchPosition()、clearWatch() 方法详解 ：https://blog.csdn.net/zyz00000000/article/details/82774543
        const geolocation = new this.BMap.Geolocation();
        // 通过 getCurrentPosition() 方法：获取当前的位置信息
        geolocation.getCurrentPosition(res => {
          //console.log("返回详细地址和经纬度", res);
          const {lng, lat} = res.point;
          const {
            province,
            city,
            district,
            street,
            street_number
          } = res.address;

          that.center = {lng, lat};
          that.choosedLocation = {
            province,
            city,
            district,
            addr: street + street_number,
            lng,
            lat
          };
        });
      }
    },
    bMapTransQQMap(lng, lat) {
      let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
      let x = lng - 0.0065;
      let y = lat - 0.006;
      let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
      let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
      let lngs = z * Math.cos(theta);
      let lats = z * Math.sin(theta);

      return {
        lng: lngs,
        lat: lats
      }
    },
    /** *
     * 地图点击事件。
     */
    getClickInfo(e) {
      // 调整地图中心位置
      this.center.lng = e.point.lng;
      this.center.lat = e.point.lat;

      // 此时已经可以获取到BMap类
      if (this.BMap) {

        // Geocoder() 类进行地址解析
        // 创建地址解析器的实例
        let geoCoder = new this.BMap.Geocoder();
        // getLocation() 类--利用坐标获取地址的详细信息
        // getPoint() 类--获取位置对应的坐标
        let that = this;
        geoCoder.getLocation(e.point, function (res) {
          //console.log("获取经纬度", e.point, "获取详细地址", res);
          // that.addressBox=[{lng:e.point.lng,lat:e.point.lat},res.address]
          // 百度地图输出 高德 的经纬度
          that.addressBox = [
            // that.$commond.bMapTransQQMap(e.point.lng, e.point.lat),
            that.bMapTransQQMap(e.point.lng, e.point.lat),
            res.address
          ];
          const addrComponent = res.addressComponents;
          const surroundingPois = res.surroundingPois;
          const province = addrComponent.province;
          const city = addrComponent.city;
          const district = addrComponent.district;
          let addr = addrComponent.street;
          if (
              surroundingPois.length > 0 &&
              surroundingPois[0].title
          ) {
            if (addr) {
              addr += `-${surroundingPois[0].title}`;
            } else {
              addr += `${surroundingPois[0].title}`;
            }
          } else {
            addr += addrComponent.streetNumber;
          }
          that.choosedLocation = {
            province,
            city,
            district,
            addr,
            ...that.center
          };
        });
      }
    },

    syncCenterAndZoom(e) {
      // 返回地图当前的缩放级别
      this.zoom = e.target.getZoom();
    },
    /** *
     * 确认
     */
    confirm: function () {
      //console.log(this.addressBox)
      // this.$emit("onSuccess",{lnBox:document.getElementById("result_").value,address:this.hosName})
      if (this.addressBox[1] == "") {
        this.$message.warning("请选取地址！");
        return false;
      }
      this.$emit("onSuccess", this.addressBox);
      this.$emit("update:visible", false);
      // this.showMapComponent = false
      // this.$emit('map-confirm', this.choosedLocation)
    },
    /** *
     * 取消
     */
    cancel: function () {
      this.$emit("update:visible", false);
      // this.showMapComponent = false
      // this.$emit('cancel', this.showMapComponent)
    },

    closeDialog() {
      this.$emit("update:visible", false);
      // return false
    },
    onSuccess() {
      this.$emit("update:visible", false);
    },
    handleCloseBindWarnStandard() {
      this.$emit("update:visible", false);
    }
  }
};
</script>
<style>
.el-dialog__body {
  padding: 5px !important;
}
</style>

