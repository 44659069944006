/*找技术*/
<template>
  <div class="gov-detailT">
    <!--      <a-page-header
              title="返回"
              @back="detailShow=false"
          />-->
    <span class="yellow text" style="float: right" v-text="selDetail.priceUccess===1?'价格面议':'货币'"></span>
    <div class="gov-detail-top">
      <div class="gov-detail-top-title">
        {{ selDetail.name }}&nbsp;
      </div>
    </div>
    <div class="gov-detail-top">
      <div class="gov-detail-top-date">
        <img class="img2" style="float: left" :src="selDetail.coverPhoto">
        <span class=""><span class="center-span">成果所有者：</span>{{ selDetail.contact }}</span><br/>
        <span class=""><span class="center-span">持有单位：</span> {{ selDetail.releaseUnit }}</span><br/>
        <span class="center-span">转让方式：</span><span class=""
                                                    v-text="selDetail.priceUccess===1?'价格面议':selDetail.price+'（元）'"></span><br/>
        <a-button v-if="$store.state.user.isPermissions()==='1'"  style="margin-bottom: 40px" class="button2" @click="showForm(selDetail)" type="danger" round>立即申请
        </a-button>
        <div v-if="$store.state.user.isPermissions()!=='1'"  style="margin-bottom: 40px" class="button2"></div>
      </div>
    </div>
    <span class="text-heda">详细说明</span>
    <div class="gov-detail-content" v-html="selDetail.resultsShowsThat"></div>
    <applicationForm v-if="visible" tebel="技术名称" :demandName="demandName" :demandId="demandId" :visible="visible"
                     :type="1"/>
  </div>
</template>
<script>
import {selPolitics} from "../../../api/company/technology";
import {getDictType} from "../../../api/company/dictType";
import applicationForm from "../../components/applicationForm";

export default {
  name: 'technologydetails',
  components: {
    applicationForm
  },
  props: {
    id: Number,
  },
  data() {
    return {
      isUserType: null,
      demandName: "",
      demandId: null,
      visible: false,
      form: {
        resultsType: null,
        name: '',
      },
      pageNum: 1,
      pageSize: 5,
      total: 10,
      navData: {
        navList: [
          {name: "法律法规规章", id: 0},
          {name: "国家政策", id: 1},
          {name: "省级政策", id: 2},
          {name: "市级政策", id: 3},
          {name: "区级政策", id: 4},
          {name: "平台政策", id: 5},
        ],
        title: "找技术",
        default: 0,
      },
      activeName: "法律法规规章",
      detailShow: false,
      current: 2,
      selDetail: {
        title:
            "湖南省实施《中华人民共和国促进科技成果转化法》办法（2019年修订）",
        publishTime: "2011/11/11",
        content:
            "产养殖数据库，立足于无人船平台，瞄准服务水产养殖全产业链，用大数据的思维，指导水产企业养殖品类与规模、指导水产养殖原料供应商生产计划、指导水产产品下游企业生产计划、支撑国家水产养殖行业精准扶持、支撑水产养殖市场政策调控，实现了公司从产品经济至数据经济",
      },
      list: [],
    };
  },
  methods: {
    showDetail(id) {
      //console.log(id);
      this.detailShow = true;
      selPolitics({id: id}).then(res => {
        //console.log(res);
        this.selDetail = res.data;
        //console.log(this.selDetail, "this.selDetail");
      })
    },
    showForm(item) {
      //console.log(this.visible)
      this.demandName = item.name;
      this.demandId = item.id;
      this.visible = true;
    },
    init() {
      getDictType(5).then(res => {
        this.navData.navList = res.data
      })
    }
  },
  mounted() {
    this.init()
  }
};
</script>
<style lang="scss" scoped>
.gov-detailT {
  height: 100%;
  background: #f8fbff;
  padding: 40px 30px;
  color: #666666;
  position: relative;

  .gov-detail-top {
    // text-align: center;
    line-height: 36px;
    border-bottom: 2px solid #efefef;
    padding-bottom: 20px;

    .gov-detail-top-title {
      color: #333333;
      font-weight: 600;
      font-size: 22px;
    }

    .gov-detail-top-date {
      font-size: 16px;
    }
  }

  .gov-detail-content {
    margin-top: 10px;
    line-height: 36px;
  }
}
</style>
