import { render, staticRenderFns } from "./vicedetails.vue?vue&type=template&id=0f58d53b&scoped=true&"
import script from "./vicedetails.vue?vue&type=script&lang=js&"
export * from "./vicedetails.vue?vue&type=script&lang=js&"
import style0 from "./vicedetails.vue?vue&type=style&index=0&id=0f58d53b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f58d53b",
  null
  
)

export default component.exports