<template>
<div class="com-box">
  <div v-show="!detailShow">
  <div>
    <div class="add-title">活动报名</div>
    <div style="margin-top: 17px;border-bottom:1px solid #DCDFE6; overflow:hidden; "></div>
  </div>
  <div class="tables">
    <div>
      <div class="img-noData" v-if="total<1"><img src="@/assets/image/noData.png" alt="" /></div>
      <div class="talent-data-list" >
        <div class="gov-span">
          <div class="gov-item" v-for="item in data" :key="item.id" @click="show(item.id)">
            <div class="gov-title">
              <img class="img1" style="float: left;" :src="item.activityImg">
              <ul>
                <li class="gov-spam-rz content3" >{{item.activityName}}</li>
                <li class="content1" >主办机构： {{item.organizer}}</li>
                <li class="content1" >承办机构：{{item.host}}</li>
                <li class="content1" >开始时间：{{item.startTime}}</li>
                <li class="content1" >地点：{{item.address}}</li>
              </ul>
            </div>
            <div class="gov-date">
              <a-button class="button2"  type="danger"  round>已报名</a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-pagination :show-total="total => `共 ${total} 条`"
                  show-quick-jumper v-if="total>5"
                  :pageSize="pageSize"
                  v-model="pageNum"
                  :total="total"
                  @change="onChange"
                  show-less-items />
  </div>
  </div>
  <div class="gov-detailS" v-show="detailShow">
    <a-page-header
              title="返回"
              @back="detailShow=false"
     />
    <div class="gov-detail-top">
      <!--        <div class="gov-detail-top-title">
                {{ selDetail.activityName }}
              </div>-->
      <div class="gov-detail-top-date">
       <a-row>
         <a-col :span="7"><div class="img3"  style="float: left;height: 400px">
           <img class="img3" style="float: left;height: 300px" :src="selDetail.activityImg" >
           <a-button  @click="showForm(selDetail)" v-if="new Date(selDetail.deadlineTime) >= new Date()"  style="margin-bottom: 20px;margin-left: 100px" class="button2"  type="danger" round>活动报名</a-button>
         </div></a-col>
         <a-col :span="17"><ul class="science-ul" style="margin-bottom: 30px;">
           <li class="content content-heda ">{{ selDetail.activityName }}</li>
           <li class="content"><span class="center-span">活动简介：</span>{{selDetail.activityIntroduction}}</li>
           <li class="content"><span class="center-span">主办机构：</span>{{selDetail.organizer}}</li>
           <li class="content"><span class="center-span">承办机构：</span>{{selDetail.host}}</li>
           <li class="content" ><span class="center-span">活动类型：</span>
             <span v-for="itemA in navData.navList" :key="itemA.id" ><span v-if="selDetail.activityTypeId===itemA.id">{{ itemA.name}}</span></span>
           </li>
           <li class="content" ><span class="center-span">活动形式：</span> {{activityFormIdMame}}</li>
           <li class="content" ><span class="center-span">活动费用：</span>{{selDetail.activityCost}}（元）</li>
           <li class="content" ><span class="center-span">人数限额：</span>{{selDetail.maxPeopel}}（人）</li>
           <li class="content" ><span class="center-span">开始时间：</span>{{selDetail.startTime}}</li>
           <li class="content" ><span class="center-span">结束时间：</span>{{selDetail.endTime}}</li>
           <li class="content" ><span class="center-span">举办地点：</span>{{selDetail.address}}</li>
           <li class="content" ><span class="center-span">报名截止时间：</span>{{selDetail.deadlineTime}}</li>
           <li class="content" ><span class="center-span">联系方式：</span>{{selDetail.phone}}</li>
         </ul></a-col>
       </a-row>
      </div>
    </div>
    <div class="gov-detail-content" >
      <div v-if="selDetail.speaker!==null&&selDetail.speaker!==''"><span class="content-heda">主讲人：</span>{{selDetail.speaker}}</div>
      <span v-if="selDetail.speakerIntroduction!==null&&selDetail.speakerIntroduction!==''">
        <div class="content-heda">主讲人简介：</div>
        <div v-html="selDetail.speakerIntroduction">{{selDetail.speakerIntroduction}}</div>
        </span>
      <span v-if="selDetail.activityContext!==null&&selDetail.activityContext!==''">
        <div class="content-heda">活动内容：</div>
        <div v-html="selDetail.activityContext"></div>
        </span>
      <div v-if="attachmentList.length>0" class="content-heda">附件：</div>
      <div v-if="attachmentList.length>0" >
        <div v-for="(img,index) in attachmentList" :key="index" >
          <div v-if="img.endsWith('.png') || img.endsWith('.jpg') || img.endsWith('.gif') || img.endsWith('.jpeg')">
            <a-popover    trigger="click" >
              <template slot="content">
                  <img  style="width: 900px;height: 600px;" :src="img" :preview-src-list="[img]">
              </template>
                <img
                    style="width: 100px;height: 100px;margin-left:3px"
                    :src="img"
                    :preview-src-list="[img]"
                />
            </a-popover>
          </div>
          <a-button  type=""   v-else @click="attachment(img)"  style="font-size: 16px;color: #666666;margin-top:10px;">{{img.substring(img.lastIndexOf("/")+1)}}</a-button>
        </div>
      </div>
    </div>
  </div>
  <addApply  v-if="visible"
             :name="activityName"
             :startTime="activityStartTime"
             :endTime="activityEndTime"
             :activityId="activityId"
             :visible="visible" />
</div>
</template>

<script>
import {activityList,selPolitics} from "../../../api/company/personal";
import {getDictType, getDictTypeById} from "../../../api/company/dictType";
import addApply from "../../science/components/addApply";
export default {
  name: "activity",
  components:{
    addApply
  },
  data() {
    return {
      visibleAA: false,
      pageNum: 1,
      pageSize: 5,
      total:10,
      data:undefined,
      type:false,
      detailShow : false,
      navData: {
        navList: [
          { name: "法律法规规章", id: 0 },
          { name: "国家政策", id: 1 },
          { name: "省级政策", id: 2 },
          { name: "市级政策", id: 3 },
          { name: "区级政策", id: 4 },
          { name: "平台政策", id: 5 },
        ],
        title: "科技活动",
        default: 0,
      },
      selDetail: {
        title:
            "湖南省实施《中华人民共和国促进科技成果转化法》办法（2019年修订）",
        publishTime: "2011/11/11",
        content:
            "产养殖数据库，立足于无人船平台，瞄准服务水产养殖全产业链，用大数据的思维，指导水产企业养殖品类与规模、指导水产养殖原料供应商生产计划、指导水产产品下游企业生产计划、支撑国家水产养殖行业精准扶持、支撑水产养殖市场政策调控，实现了公司从产品经济至数据经济",
      },
      attachmentList:[],
      activityFormIdMame:'',
      visible:false,
      activityName:null,
      activityStartTime:null,
      activityEndTime:null,
      activityId:null,
    }
  },
  methods: {
    hide() {
      //console.log(111);
      this.visibleAA = false;
    },
    init() {
      activityList({'pageNum': this.pageNum, 'pageSize': this.pageSize}).then(res => {
        let {total, records} = res.data
        //console.log(res,"+++++++++++++")
        this.data = records
        if(this.data===undefined||this.data===null){
          this.type = false
        }else {
          this.type = true
        }
        this.total = total
      })
    },
    async show(id){
      this.detailShow = true;
      await getDictType(60).then(res=>{
        this.navData.navList=res.data
      })
      await selPolitics({id:id}).then(res=>{
        //console.log(res);
        this.selDetail=res.data;
        if(this.selDetail.attachment!==''&&this.selDetail.attachment!==null){
          this.attachmentList=this.selDetail.attachment.split(",")
        }
        //console.log(this.selDetail,"this.selDetail");
        //console.log(this.selDetail,this.attachmentList);
      })
      await getDictTypeById(this.selDetail.activityFormId).then(res=>{
        this.activityFormIdMame=res.msg;
      })
    },
    attachment(img){
      window.open(img)
    },
    showForm(item){
      if(item.maxPeopel<=0){
        this.$message.success("报名人数已满")
        return
      }
      this.activityName=item.activityName;
      this.activityStartTime=item.startTime,
          this.activityEndTime=item.endTime,
          this.activityId=item.id,
          this.visible=true;
    },
    onChange(obj){
      this.pageNum = obj
      this.init();
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.talent-data-list {
.gov-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 10px 0;
  border-bottom: 2px solid #efefef;
  cursor: pointer;
.gov-title {
  width: 900px;
  color: #333333;
  display: flex;
}
.gov-date {
  color: #999999;
}
}
.gov-span :hover{
.gov-spam-rz {
  color : #044fc6;
}
}
}
.com-box {
  min-height: calc(100vh - 142px);
  padding: 20px;
  color: #000000;
}

.add-title {
  text-align: left;
  font-size: 20px;
  font-weight: bold
}
.gov-detailS {
  width: 1080px;
  background: #f8fbff;
  padding: 0px 30px;
  color: #666666;
  position: relative;
  .gov-detail-top {
    //text-align: center;
    line-height: 36px;
    border-bottom: 2px solid #efefef;
    padding-bottom: 10px;
    .gov-detail-top-title {
      color: #333333;
      font-weight: 600;
      font-size: 22px;
    }
    .gov-detail-top-date {
      font-size: 16px;
    }
  }
  .gov-detail-content {
    margin-top: 40px;
    line-height: 36px;
  }
}
.science-ul li{
  height: 28px;
}
</style>
<style lang="scss">
</style>
