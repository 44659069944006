<template>
  <div class="talent-page-content">
        <t-nav
            :list="navData.navList"
            :name="navData.title"
            :defaultNav="current"
            @changeNav="changeNav"
            @changeNavA="changeNavA"
            ref="tNav"
        />
      <div class="talent-data-content">
        <business v-if="listA===1" ref="business"></business>
        <release ref="release" v-else-if="listA === 2"></release>
        <technology ref="technology" v-else-if="listA === 3"></technology>
        <moneyApply ref="moneyApply" v-else-if="listA === 4"></moneyApply>
        <serveApply ref="serveApply" v-else-if="listA === 5"></serveApply>
        <professorTeble  v-else-if="listA===6"  ref="professorTeble"></professorTeble>
        <activity ref="activity" v-else-if="listA === 7"></activity>
        <demand  ref="demand" v-else-if="listA === 8"></demand>
        <AccountSet v-else-if="listA === 11"></AccountSet>
      </div>

    <Back ref="Back"></Back>
  </div>
</template>
<script>
import AccountSet from '../components/AccountSet'
import Back from '../components/back'
import {getPoliticsList, selPolitics} from "../../api/company/politics";
import business from "../personalCenter/components/business";
import release from "../personalCenter/components/release";
import technology from "../personalCenter/components/technology";
import moneyApply from "../personalCenter/components/moneyApply";
import serveApply from "../personalCenter/components/serveApply";
import activity from "../personalCenter/components/activity";
import demand from "../personalCenter/components/demand";

import  professorTeble from  '../personal/components/professorTeble.vue'
export default {
  name: 'personalCenter',
   components: {
    professorTeble,business, release, technology ,moneyApply ,serveApply ,activity,AccountSet, Back,demand
  },
  data() {
    return {
      listA:null,
      form:{
        dictId:null,
        title:'',
      },
      pageNum:1,
      pageSize:10,
      total:10,
      navData: {
        navList: [
          { name: "我的信息", id: 0 },
          { name: "管理中心", id: 1 ,hasChildren:true},
          // { name: "账号设置", id: 2 },
          { name: "退出", id: 3 },
        ],
        title: "我的",
        default: 0,
      },
      activeName: "法律法规规章",
      detailShow: false,
      current: 1,
      selDetail: {
        title:
            "湖南省实施《中华人民共和国促进科技成果转化法》办法（2019年修订）",
        publishTime: "2011/11/11",
        content:
            "产养殖数据库，立足于无人船平台，瞄准服务水产养殖全产业链，用大数据的思维，指导水产企业养殖品类与规模、指导水产养殖原料供应商生产计划、指导水产产品下游企业生产计划、支撑国家水产养殖行业精准扶持、支撑水产养殖市场政策调控，实现了公司从产品经济至数据经济",
      },
      list:[],
    };
  },
  methods: {
    change() {
      getPoliticsList({pageNum: this.pageNum, pageSize: this.pageSize, form: this.form}).then(res => {
        //console.log(res);
        this.list = res.data.list;
        this.total = res.data.total;
      });
    },
    changeNav(item) {
      if (item.id === 0) {
        this.listA = 11
      }
      if (item.id === 3) {
        this.$refs.Back.show()
      }
    },
    changeNavA(item) {
      this.listA = item;
      if (item === 1) {
        //console.log(this.$refs.business)
        this.$refs.business.init()
      } else if (item === 2) {
        this.$refs.release.init()
      }
    },
    onSearch(val) {
      this.form.title = val;
      getPoliticsList({pageNum: 1, pageSize: this.pageSize, form: this.form}).then(res => {
        //console.log(res);
        this.list = res.data.list;
        this.total = res.data.total;
      });
    },
    showDetail(id) {
      //console.log(id);
      this.detailShow = true;
      selPolitics({id: id}).then(res => {
        //console.log(res);
        this.selDetail = res.data;
        //console.log(this.selDetail, "this.selDetail");
      })
    },
    init(){
      let index=this.$route.query.index;
      this.listA = parseInt(index);
      if(parseInt(index)===1){
        this.$refs.tNav.selNav({ name: "管理中心", id: 1 ,hasChildren:true})
        let NavA=this.$store.state.user.type() === '1'?1:8;
        this.$refs.tNav.selNavA(NavA)
        this.$refs.business.init()
      }else{
        this.$refs.tNav.selNav({ name: "我的信息", id: 0 })
      }
      //console.log(this.active,this.current)
    }
  },
  mounted() {
    this.init()
  }
}
</script>
<style lang="scss" scoped>
.personalCenter-heda{
  padding-top: 26px;

  .heda{
    width: 300px;
    height: 1000px;
   // background-color: #333333;
    border: 2px solid #E1E1E1;
  }
  .thetail{
    margin-left: 15px;
    width:100%;
    height: 1000px;
    //border: 2px solid #E1E1E1;
  }
}
</style>
