<!--政策-->
<template>
  <div class="gov-detailD" v-show="detailShow">
    <span class="yellow text" style="float: right" v-text="selDetail.rewardCurrencyType==1?'价格面议':'货币'"></span>
    <!--      <a-page-header
              style="float: right"
              title="返回"
              @back="detailShow=false"
          />-->
    <div class="gov-detail-top">
      <div class="gov-detail-top-title"><span class="gov-detail-top-span" v-text="selDetail.type==0?'对接中':'对接中'"></span>{{
          selDetail.title
        }}&nbsp;
      </div>
    </div>
    <div class="gov-detail-top" style="padding-bottom: 50px">
      <div class="gov-detail-top-date" style="float: right" @click="showForm(selDetail)">
        <a-button v-if="$store.state.user.isPermissions()==='1'"  class="button2" type="danger" round>立即申请</a-button>
      </div>
      <div class="gov-detail-top-date"><span
          class="center-span">发布人（单位）：</span>{{ selDetail.personExternal }}（{{ selDetail.unitExternal }}）
      </div>
      <div class="gov-detail-top-date"><span class="center-span">需求类型：</span>{{ categoryName }}</div>
      <div class="gov-detail-top-date"><span class="center-span">需求经费：</span><span
          v-text="selDetail.rewardCurrencyType==1?'价格面议':selDetail.payAmount+'（元）'"></span></div>
      <div class="gov-detail-top-date"><span class="center-span">技术领域：</span>{{ technicalFieldName }}</div>
      <div class="gov-detail-top-date"><span class="center-span">截止时间：</span>{{ selDetail.demandPeriod }}</div>
      <br/>
    </div>
    <span class="text-heda">需求描述</span>
    <div class="gov-detail-content" v-html="selDetail.description">内容</div>
    <applicationForm v-if="visible" tebel="需求名称" :demandName="demandName" :demandId="demandId" :visible="visible"
                     :type="0"/>
  </div>
</template>
<script>
import {selPolitics} from "../../../api/company/demand_information";
import {getDictType, getDictTypeById} from "../../../api/company/dictType";
import applicationForm from "../../components/applicationForm";

export default {
  name: 'demanddetails',
  components: {
    applicationForm
  },
  props: {
    id: Number,
  },
  data() {
    return {
      isUserType:undefined,
      demandName: "",
      demandId: null,
      visible: false,
      categoryName: '',
      active: "",
      technicalFieldList: [],
      activeName: "全部需求",
      detailShow: false,
      current: 2,
      technicalFieldName: null,
      selDetail: {
        title:
            "湖南省实施《中华人民共和国促进科技成果转化法》办法（2019年修订）",
        publishTime: "2011/11/11",
        content:
            "产养殖数据库，立足于无人船平台，瞄准服务水产养殖全产业链，用大数据的思维，指导水产企业养殖品类与规模、指导水产养殖原料供应商生产计划、指导水产产品下游企业生产计划、支撑国家水产养殖行业精准扶持、支撑水产养殖市场政策调控，实现了公司从产品经济至数据经济",
      },
    };
  },
  methods: {
    async showDetail(id) {
      //console.log(id);
      this.detailShow = true;
      await selPolitics({id: id}).then(res => {
        //console.log(res);
        this.selDetail = res.data;
        //console.log(this.selDetail, "this.selDetail");
        let aaa = '';
        for (let i = 0; i < this.technicalFieldList.length; i++) {
          if (this.selDetail.technicalField.indexOf(this.technicalFieldList[i].id) >= 0) {
            if (aaa === '') {
              aaa += this.technicalFieldList[i].name;
            } else {
              aaa += "/" + this.technicalFieldList[i].name;
            }
          }
        }
        this.technicalFieldName = aaa;
        //console.log(this.technicalFieldName, aaa);
      })
      getDictTypeById(this.selDetail.category).then(res => {
        this.categoryName = res.msg;
      })
    },
    showForm(item) {
      //console.log(this.visible)
      this.demandName = item.title;
      this.demandId = item.id;
      this.visible = true;
    },
  },
  mounted() {
    getDictType(30).then(res => {
      this.technicalFieldList = res.data;
    })
  }
};
</script>
<style lang="scss" scoped>
.gov-detailD {
  height: 100%;
  background: #f8fbff;
  padding: 40px 30px;
  color: #666666;
  position: relative;

  .gov-detail-top {
    line-height: 36px;
    border-bottom: 2px solid #efefef;
    padding-bottom: 20px;

    .gov-detail-top-title {
      color: #333333;
      font-weight: 600;
      font-size: 20px;
      .gov-detail-top-span{
        background:  #ff9a02;
        font-size: 17px;
        color: #ffffff;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
        margin-right: 8px;
      }
    }

    .gov-detail-top-date {
      font-size: 16px;
    }
  }

  .gov-detail-content {
    margin-top: 10px;
    line-height: 36px;
  }
}
</style>
