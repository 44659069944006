/*找服务*/
<template>
  <div class="gov-detailV">
    <!--      <a-page-header
              title="返回"
              @back="detailShow=false"
          />-->
    <div class="gov-detail-top">
      <!--        <div class="gov-detail-top-title">
                {{ selDetail.serviceName }}
              </div>-->
      <a-row>
        <a-col :span="9">
          <div class="gov-detail-top-date">
            <div class="img3" style="height: 300px;">
              <img class="img3" :src="selDetail.img">
              <a-button style="margin-left: 100px" class="button2" @click="showForm(selDetail)" type="danger" round>提交需求
              </a-button>
            </div>
          </div>
        </a-col>
        <a-col :span="15">
          <ul style="margin-bottom: 70px;">
            <li class="content content-heda">{{ selDetail.serviceName }}</li>
            <li class="content"><span class="center-span">发布单位：</span>{{ selDetail.releaseUnit }}</li>
            <li class="content">
              <span class="center-span">服务收费：</span>
              <span v-text="selDetail.chargeType===1?'价格面议':selDetail.charge+'（元）'"></span>
            </li>
            <li class="content"><span class="center-span">联系人：</span>{{ selDetail.serviceLinkman }}</li>
            <li class="content"><span class="center-span">联系方式：</span>{{ selDetail.phone }}</li>
            <br/>
          </ul>
        </a-col>
      </a-row>


    </div>
    <!--      <span style="border-bottom: 2px solid blue;position:relative;bottom:22px">产品介绍</span>-->
    <div class="gov-detail-content">
      <div class="content-heda">服务简介：</div>
      <div v-html="selDetail.serviceIntroduction"></div>
      <div class="content-heda">服务内容：</div>
      <div v-html="selDetail.serviceContext"></div>
      <div class="content-heda">服务流程：</div>
      <div v-html="selDetail.serviceProcess"></div>
      <div class="content-heda">服务周期：</div>
      <div v-html="selDetail.serviceCycle"></div>
    </div>
    <applicationForm v-if="visible" tebel="服务名称" :demandName="demandName" :demandId="demandId" :visible="visible"
                     :type="3"/>
  </div>
</template>
<script>
import {selPolitics} from "../../../api/company/vice";
import applicationForm from "../../components/applicationForm";

export default {
  name: 'vicedetails',
  components: {
    applicationForm
  },
  props: {
    id: Number,
  },
  data() {
    return {
      demandName: "",
      demandId: null,
      visible: false,
      selDetail: {
        title:
            "湖南省实施《中华人民共和国促进科技成果转化法》办法（2019年修订）",
        publishTime: "2011/11/11",
        content:
            "产养殖数据库，立足于无人船平台，瞄准服务水产养殖全产业链，用大数据的思维，指导水产企业养殖品类与规模、指导水产养殖原料供应商生产计划、指导水产产品下游企业生产计划、支撑国家水产养殖行业精准扶持、支撑水产养殖市场政策调控，实现了公司从产品经济至数据经济",
      },
    };
  },
  methods: {
    showForm(item) {
      //console.log(this.visible)
      this.demandName = item.serviceName;
      this.demandId = item.id;
      this.visible = true;
    },
    showDetail(id) {
      selPolitics({id: id}).then(res => {
        //console.log(res);
        this.selDetail = res.data;
        //console.log(this.selDetail, "this.selDetail");
      })
    },
    init() {
      this.showDetail(this.id);
    }
  },
  mounted() {

  }
};
</script>
<style lang="scss" scoped>
.gov-detailV {
  width: 100%;
  height: 100%;
  background: #f8fbff;
  padding: 40px 30px;
  color: #666666;
  position: relative;

  .gov-detail-top {
    //text-align: center;
    line-height: 36px;
    border-bottom: 2px solid #efefef;
    padding-bottom: 20px;

    .gov-detail-top-title {
      color: #333333;
      font-weight: 600;
      font-size: 22px;
    }

    .gov-detail-top-date {
      font-size: 16px;
    }
  }

  .gov-detail-content {
    margin-top: 20px;
    line-height: 28px;
  }
}
</style>
