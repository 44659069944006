/*找专家*/
<template >
    <div>
    <div class="talent-data-content" v-show="isShow>0">
      <div class="talent-data-title">专家关注</div>
      <div class="talent-data-search">
<!--        <a-input-search
            class="talent-nav-search-input"
            placeholder="请输入要搜索的内容"
            enter-button="搜索"
            size="large"
            @search="onSearch"
        />-->
      </div>
      <div class="img-noData" v-if="list.length===0"><img src="@/assets/image/noData.png" alt="" /></div>
      <div class="talent-data-list" v-if="list.length>0">
        <div class="gov-span">
          <div class="gov-item" v-for="item in list" :key="item.id" @click="showDetail(item.id)">
            <!--             @click="showDetail(item.id)">-->
            <div class="gov-title">
              <img class="img1" style="float: left" :src="item.img">
              <span class=" gov-spam-rz content3">{{item.name}}</span><br/>
              <span class="content1" >单位：{{item.workUnits}}</span><br/>
              <span class="content1" >职务：{{item.post}}</span>
            </div>
            <div class="gov-date">
              <a-button v-if="$store.state.user.isPermissions()==='1'" class="button2"  type="danger" @click.stop="FocusOn(item)" v-text="item.focusOn===1?'取消关注':'关注'" round>关注</a-button>
              <!--            {{item.researchDirection}}-->
            </div>
          </div>
        </div>
        <a-pagination :show-total="total => `共 ${total} 条`"  show-quick-jumper v-if="total>5" :pageSize="pageSize" v-model="pageNum" :total="total" @change="change" show-less-items />
      </div>
    </div>
      <a-page-header
          title="返回"
          @back="isShow=1"
          v-show="isShow<0"
      >
      <professordetails  :id="professorId"  ref="professordetails"></professordetails>
      </a-page-header>
    </div>
</template>
<script>
import {delSysUserProfessor,addSysUserProfessor} from "../../../api/company/professor";
import {getPoliticsList} from "../../../api/company/sysUserProfessor";
import {getDictType} from "../../../api/company/dictType";
import  professordetails from  '../details/professordetails.vue'
export default {
  name:'professorTeble',
  components:{
    professordetails
  },
  data() {
    return {
      isShow:1,
      professorId:null,
      form:{
        userId:null,
        dictId:null,
        name:'',
      },
      pageNum:1,
      pageSize:5,
      total:10,
      navData: {
        navList: [
          { name: "法律法规规章", id: 0 },
          { name: "国家政策", id: 1 },
          { name: "省级政策", id: 2 },
          { name: "市级政策", id: 3 },
          { name: "区级政策", id: 4 },
          { name: "平台政策", id: 5 },
        ],
        title: "找专家",
        default: 0,
      },
      activeName: "法律法规规章",
      detailShow: false,
      current: 2,
      list:[],
    };
  },
  methods: {
    showDetail(id){
      //console.log(id,"id")
      this.isShow*=-1;
      // this.professorId=id
      this.$refs.professordetails.showDetail(id)
    },
    FocusOn(item){
      if(this.isUserType===null){
        this.$message.success("请登陆")
        return
      }
      if(item.focusOn===1){
       delSysUserProfessor({professorId:item.id}).then(()=>{
          this.$message.success(
              "取消关注"
          );
         item.focusOn=0
       })
      }else {
         addSysUserProfessor({professorId:item.id}).then(()=>{
            this.$message.success(
                "关注成功"
            );

          item.focusOn=1
        })

      }

    },
    change(){
      getPoliticsList({pageNum:this.pageNum,pageSize:this.pageSize,form:this.form}).then(res=>{
        //console.log(res);
        this.list=res.data.records;
        this.total=res.data.total;
      });
    },
    changeNav(item) {
      this.detailShow = false;
      //console.log(this.detailShow)
      //todo 切换分类 重新加载数据

      this.activeName = item.name;
      //console.log(item);
      this.form.dictId=item.id;
      getPoliticsList({pageNum:1,pageSize:this.pageSize,form:this.form}).then(res=>{
        //console.log(res);
        this.list=res.data.records;
        this.total=res.data.total;
      });
    },
    onSearch(val) {
      this.form.name=val;
      getPoliticsList({pageNum:1,pageSize:this.pageSize,form:this.form}).then(res=>{
        //console.log(res);
        this.list=res.data.records;
        this.total=res.data.total;
      });
    },
    init(){
      getDictType(5).then(res=>{
        this.navData.navList=res.data
        this.changeNav({id:null,name:'专家'});
      })
    }
  },
  mounted() {
    this.init();
  }
};
</script>
<style lang="scss" scoped>
.ant-page-header-ghost[data-v-3ac4bb82]{
  padding-left: 0px;
  padding-right:0px ;
}
.talent-data-search {
  margin: 25px 0;
  .talent-nav-search-input {
    width: 419px;
    .ant-btn {
      background: #ff9a02;
      border-color: #ff9a02;
      border-radius: 0;
    }
  }
}
.talent-data-list {
  .gov-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    padding: 10px 0;
    border-bottom: 2px solid #efefef;
    cursor: pointer;
    .gov-title {
      width: 900px;
      color: #333333;
    }
    .gov-date {
      color: #999999;
    }
  }
  .gov-span :hover{
    .gov-spam-rz {
      color : #044fc6;
    }
  }
}
</style>
<style lang="scss">
.ant-page-header-ghost[data-v-3ac4bb82] {
  .ant-page-header-back {
    padding-left: 30px !important;
  }
}
</style>