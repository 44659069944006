<!-- 我的发布 -->
<template>
  <div class="com-box">
    <div v-show="!detailShow">
    <div>
      <div class="add-title">我的发布</div>
      <div style="margin-top: 17px;border-bottom:1px solid #DCDFE6; overflow:hidden; "></div>
    </div>
    <div class="tables">
      <a-table
          :data-source="data"
          :columns="columns"
          :pagination="false">
    <span slot="status" slot-scope="record">
      <a-switch :checked="record.isRelease===1" @change="upd(record.isRelease,record)"/>
    </span>
        <span slot="action" slot-scope="text,record">
      <a-button @click="show(record)" type="link">查看</a-button>
    </span>
      </a-table>
      <a-pagination show-quick-jumper
                    :show-total="total => `共 ${total} 条`"
                    :default-current="this.pageNum"
                    :total="this.total"
                    @change="onChange"
                    :defaultPageSize="this.pageSize"
                    :hideOnSinglePage="true"/>
    </div>
    </div>
    <div v-show="detailShow">
      <a-page-header
          title="返回"
          @back="detailShow=false"
      />
      <demanddetails v-show="this.type === 0" ref="demanddetails"></demanddetails>
      <technologydetails v-show="this.type === 1" ref="technologydetails"></technologydetails>
      <sciencedetails v-show="this.type === 2" ref="sciencedetails"></sciencedetails>
      <vicedetails v-show="this.type === 3" ref="vicedetails"></vicedetails>
    </div>
  </div>
</template>

<script>

import {myReleaseList, updateReleaseState} from "../../../api/company/personal";
import demanddetails from "../../personal/details/demanddetails";
import technologydetails from "../../personal/details/technologydetails";
import sciencedetails from "../../personal/details/sciencedetails";
import vicedetails from "../../personal/details/vicedetails";

const columns = [
  {
    title: '业务名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '业务类型',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: '发布时间',
    dataIndex: 'releaseTime',
    key: 'releaseTime',
  },
  {
    title: '是否发布',
    align:'center',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    align:'center',
    scopedSlots: { customRender: 'action' },
  }
];

const data = [];

export default {
  name: 'release',
  components : {
    demanddetails ,technologydetails,sciencedetails,vicedetails
  },
  data() {
    return {
      detailShow: false,
      type : undefined,
      pageNum: 1,
      pageSize: 5,
      total: 10,
      data,
      columns
    }
  },
  methods: {
    onChange(obj) {
      //console.log("obj",obj)
      this.pageNum = obj
      this.init()
    },
    upd(check,obj) {
      //console.log('obj',obj)
      //console.log('check',check)
      if (check === 0) {
        obj.isRelease=1;
        updateReleaseState({'projectId': obj.id, 'isRelease': 1, 'type':obj.typeLabel}).then()
          this.$message.success('操作成功')
      } else {
        obj.isRelease=0;
        updateReleaseState({'projectId': obj.id, 'isRelease': 0, 'type':obj.typeLabel}).then()
          this.$message.success('操作成功')
      }
    },
    init() {
      //console.log(8888888888888888888888)
      myReleaseList({'pageNum': this.pageNum, 'pageSize': this.pageSize}).then(res => {
        let {records, total} = res.data
        this.data = records
        this.total = total
      })
    },
    show(obj) {
      this.init()
      if (obj.typeLabel === 0) {
        // 说明是业务类型是需求
        this.$refs.demanddetails.showDetail(obj.id)
        this.type = obj.typeLabel
        this.detailShow = true
      }else if (obj.typeLabel === 1) {
        //说明是业务类型是技术
        this.$refs.technologydetails.showDetail(obj.id)
        this.type = obj.typeLabel
        this.detailShow = true
      }else if (obj.typeLabel === 2) {
        //说明是业务类型是活动
        this.$refs.sciencedetails.showDetail(obj.id)
        this.type = obj.typeLabel
        this.detailShow = true
      }else if (obj.typeLabel === 3) {
        // 说明是业务类型是服务
        this.$refs.vicedetails.showDetail(obj.id)
        this.type = obj.typeLabel
        this.detailShow = true
      }
    }
  },
  mounted() {
    this.init();
  }
}
</script>

<style lang="scss" scoped>

.com-box {
  min-height: calc(100vh - 142px);
  padding: 20px;
  color: #000000;
}

.add-title {
  text-align: left;
  font-size: 20px;
  font-weight: bold
}

.gov-detailS {
  width: 1080px;
  background: #f8fbff;
  padding: 0px 30px;
  color: #666666;
  position: relative;

  .gov-detail-top {
    //text-align: center;
    line-height: 36px;
    border-bottom: 2px solid #efefef;
    padding-bottom: 10px;

    .gov-detail-top-title {
      color: #333333;
      font-weight: 600;
      font-size: 22px;
    }

    .gov-detail-top-date {
      font-size: 16px;
    }
  }

  .gov-detail-content {
    margin-top: 40px;
    line-height: 36px;
  }
}

</style>
