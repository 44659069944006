/*找专家*/
<template >
    <div class="gov-detailP" >
      <div class="gov-detail-top">
        <!--        <div class="gov-detail-top-title">
                  {{ selDetail.name }}
                </div>-->
        <div class="gov-detail-top-date">
          <a-row>
            <a-col :span="9">
              <div class="img3"  style=";height: 400px">
                <img class="img3" style="float: left;height: 300px" :src="selDetail.img" >
                <a-button v-if="$store.state.user.isPermissions()==='1'"  style="margin-bottom: 20px;margin-left: 100px" class="button2"  type="danger" @click="FocusOn(selDetail)" v-text="selDetail.focusOn===1?'已关注':'关注'"  round></a-button>
              </div>
            </a-col>
            <a-col :span="15">
              <ul class="science-ul" style="margin-bottom: 200px;">
                <li class="content"><span class="center-span">专家姓名：</span>{{selDetail.name}}</li>
                <li class="content"><span class="center-span">工作单位：</span>{{selDetail.workUnits}}</li>
                <li class="content" ><span class="center-span">专家类型：</span>
                  <span v-for="itemA in navData.navList" :key="itemA.id" ><span v-if="selDetail.dictId===itemA.id">{{ itemA.name}}</span></span>
                </li>
                <li class="content" ><span class="center-span">专家职务：</span> {{selDetail.post}}</li>
                <li class="content" ><span class="center-span">科研方向：</span>{{selDetail.researchDirection}}</li>
                <li class="content" ><span class="center-span">科研成果：</span>{{selDetail.researchResult}}</li>
              </ul>
            </a-col>
          </a-row>
        </div>
      </div>
      <span class="text-heda">简介</span>
      <div class="gov-detail-content" v-html="selDetail.introduction"></div>
    </div>
</template>
<script>
import {delSysUserProfessor,addSysUserProfessor,selPolitics} from "../../../api/company/professor";
import {getDictType} from "../../../api/company/dictType";
export default {
  name:'professordetails',
  props:{
    id : Number,
  },
  data() {
    return {
      navData: {
        navList: [
          { name: "法律法规规章", id: 0 },
          { name: "国家政策", id: 1 },
          { name: "省级政策", id: 2 },
          { name: "市级政策", id: 3 },
          { name: "区级政策", id: 4 },
          { name: "平台政策", id: 5 },
        ],
        title: "找专家",
        default: 0,
      },
      activeName: "法律法规规章",
      detailShow: false,
      current: 2,
      selDetail: {
        title:
            "湖南省实施《中华人民共和国促进科技成果转化法》办法（2019年修订）",
        publishTime: "2011/11/11",
        content:
            "产养殖数据库，立足于无人船平台，瞄准服务水产养殖全产业链，用大数据的思维，指导水产企业养殖品类与规模、指导水产养殖原料供应商生产计划、指导水产产品下游企业生产计划、支撑国家水产养殖行业精准扶持、支撑水产养殖市场政策调控，实现了公司从产品经济至数据经济",
      },
    };
  },
  methods: {
    isShow(){
      alert("qeqwe")
      this.$parent.isShow=1;
    },
    FocusOn(item){
      if(item.focusOn!==1){
        addSysUserProfessor({professorId:item.id}).then(()=>{
          this.$message.success(
              "关注成功"
          );
          item.focusOn=1
        })
      }else {
        delSysUserProfessor({professorId:item.id}).then(()=>{
          this.$message.success(
              "取消关注"
          );
          item.focusOn=0
        })

      }

    },
    showDetail(id) {
      //console.log(id);
      this.detailShow = true;
      selPolitics({id:id}).then(res=>{
        //console.log(res);
        this.selDetail=res.data;
        //console.log(this.selDetail,"this.selDetail");
      })
    },
    init(){
      getDictType(9).then(res=>{
        this.navData.navList=res.data
      })
    }
  },
  mounted() {
    this.init();
  }
};
</script>
<style lang="scss" scoped>
.gov-detailP {
  height:999px;
  background: #f8fbff;
  padding: 40px 30px;
  color: #666666;
  position: relative;
  .gov-detail-top {
    //text-align: center;
    line-height: 36px;
    border-bottom: 2px solid #efefef;
    padding-bottom: 20px;
    .gov-detail-top-title {
      color: #333333;
      font-weight: 600;
      font-size: 22px;
    }
    .gov-detail-top-date {
      font-size: 16px;
    }
  }
  .gov-detail-content {
    margin-top: 40px;
    line-height: 36px;
  }
}
</style>