<template>
  <div >
    <a-modal @cancel="turnOff" v-model="visible" width="500" class="editPass-modal" :footer="null" :centered="true">
      <a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol"
                    :wrapper-col="wrapperCol">
        <a-form-model-item label="旧密码" prop="oldPassword">
          <a-input v-model="form.oldPassword" type="password" style="width: 300px;"/>
        </a-form-model-item>
        <a-form-model-item label="新密码" prop="newPassword">
          <a-input v-model="form.newPassword" @keydown.native="keyDown" type="password" style="width: 300px;"/>
        </a-form-model-item>
        <a-form-model-item label="确认密码" prop="cPassword">
          <a-input v-model="form.cPassword" @keydown.native="keyDown" type="password" style="width: 300px;"/>
        </a-form-model-item>
        <a-form-model-item label="验证码" required>
          <div style="display: flex">
            <div>
              <a-form-model-item prop="validCode">
                <a-input v-model="form.validCode" style="width: 215px"/>
              </a-form-model-item>
            </div>
            <div>
              <valid-code ref="validCode" :value.sync="vCode"></valid-code>
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }" style="margin-left: 100px;bottom: 10px;">
          <a-button type="primary" @click="turnOff">
            返回
          </a-button>
          <a-button style="margin-left: 10px;" @click="onSubmit">
            提交
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <login ref="login"></login>
  </div>
</template>

<script>
import login from '../components/login'
import {resetPassword} from '../../api/user'
import validCode from '@/components/identify'
import {removeToken} from "../../utils/auth";

export default {
  name: "EditPass",
  components: {validCode, login},
  data() {
    var validatePass = (rule, value, callback) => {
      let reg=/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/
      if (value === '' || value === null) {
        callback(new Error('请输入密码'));
      } else if(value.length < 6){
        callback(new Error('密码长度最小6位'));
      }else if(!reg.test(value)){
        callback(new Error('密码要同时含有数字和字母'));
      }else {
        callback();
      }
    };
    return {
      visible: false,
      vCode: null,
      labelCol: {span: 5},
      wrapperCol: {span: 5},
      form: {
        oldPassword: null,
        newPassword: null,
        cPassword: null,
        validCode: null
      },
      rules: {
        oldPassword: [{required: true,validator: validatePass, trigger: 'blur'}],
        newPassword: [{required: true,validator: validatePass, trigger: 'blur'}],
        cPassword: [{required: true,validator: validatePass, trigger: 'blur'}],
        validCode: [{required: true, message: '请输入验证码', trigger: 'blur'}],
      }
    }
  },
  methods: {
    keyDown(e) {
      if (e.keyCode == 32) {
        e.returnValue = false
      }
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.newPassword !== this.form.cPassword) {
            this.form.cPassword = null
            this.$message.warning('密码确认错误,请重新输入确认密码');
            return
          }
          if (this.form.validCode.toLowerCase() !== this.vCode.toLowerCase()) {
            this.form.validCode = null
            this.$refs.validCode.refreshCode();
            this.$message.warning('验证码错误');
            return
          }
          resetPassword(this.form).then(() => {
            this.$message.success("请重新登录")
            removeToken()
            this.visible = false
            this.$router.push({path: '/home',query:{pas:'1'}})
            location.reload()
          })
        }
      })

    },
    turnOff() {
      this.visible = false
      this.$refs.form.resetFields()
    },
    show() {
      this.visible = true
    }
  }
}
</script>

<style lang="scss">
.editPass-modal {
  .ant-modal-content {
    width: 500px !important;
    padding-bottom:unset !important;
  }
  .ant-modal-body {
    background-image: url("../../assets/image/long_backgroud.jpg");
    background-size: 100% 100%;
  }
  .has-error .ant-form-explain, .has-error .ant-form-split {
    width: 200px;
  }
}
</style>
